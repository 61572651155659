
import Vue from "vue";
import { yesNoSchema } from "./basicForm";
export default Vue.extend({
  name: "form-builder-example-quiz",
  components: {
    "form-builder": () => import("../FormBuilder.vue")
  },
  data() {
    return {
      schemas: yesNoSchema,
      quizQuestions: {
        philosophy: "",
        weather: "",
        group: "",
        fein: "",
        gender: ""
      },
      formIsValid: false
    };
  },
  methods: {
    formFieldChangedHandler(event: { key: string; value: any }) {
      const { key, value } = event;
      (this.quizQuestions as any)[key] = value;
    }
  },
  watch: {
    quizQuestions: {
      deep: true,
      handler() {
        console.log((this.$refs.yesNoFormBuilder as any).isFormValid());
      }
    }
  }
});
